import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import {
	BryntumGrid,
	BryntumScheduler,
	BryntumSplitter,
} from '@bryntum/scheduler-react'
import dayjs from 'dayjs'
import { DatePicker, Select, Drawer, FloatButton, Button, Input } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { ProductionEntry, EventEntry, GroupedJobsData } from '@app/shared'
import { Schedule, Stage, Warehouse } from '@prisma/client'
import {
	DependencyStore,
	EventStore,
	ResourceStore,
	DomHelper,
	EventModel,
	// ResourceModel,
} from '@bryntum/scheduler'
import { format, isFriday, isMonday, isWeekend, nextMonday } from 'date-fns'
import { data, schedulerConfig } from 'src/AppConfig'
import { fetcher, handleFetcherError } from 'src/fetcher'
import Task from 'src/lib/Task'
import TaskStore from 'src/lib/TaskStore'
import Drag from 'src/lib/Drag'
import { UnplannedGrid } from 'src/components/UnplannedGrid/UnplannedGrid'
import JobScheduleTable from 'src/components/JobScheduleTable/JobScheduleTable'
import Popup from 'src/components/Popup/Popup'
import withAuth from 'src/HOC/withAuth'

const Scheduler = ({ user }) => {
	const schedulerRef = useRef<BryntumScheduler>()
	const gridRef = useRef<BryntumGrid>()
	const [ready, setReady] = useState(false)
	const [popupShown, showPopup] = useState(false)
	const [openScheduleTable, setOpenScheduleTable] = useState(false)
	const isAdmin = useMemo(
		() => user?.Type?.name === 'Production manager',
		[user?.Type?.name]
	)
	const showDrawer = () => {
		setOpenScheduleTable(true)
	}

	const onClose = () => {
		setOpenScheduleTable(false)
	}

	const [config, setConfig] = useState(schedulerConfig)

	const [eventRecord, setEventRecord] = useState(null)
	const [eventStore, setEventStore] = useState<EventStore>(null)
	const [resourceStore, setResourceStore] = useState<ResourceStore>(null)
	const [dependencyStore, setDependencyStore] = useState<DependencyStore>(null)

	const [resources, setResource] = useState([])
	const [date, setDate] = useState(dayjs())
	const [startDate, setStartDate] = useState<string>()
	const [endDate, setEndDate] = useState<string>()

	const [warehouses, setWarehouses] = useState([])
	const [warehouse, setWarehouse] = useState('01')
	const [theme, setTheme] = useState('classic-dark')

	const [jobsScheduled, setJobsScheduled] = useState([])
	const [jobsUnScheduled, setJobsUnScheduled] = useState([])
	const [jobsSplitUnScheduled, setJobsSplitUnScheduled] = useState([])
	// const [jobsScheduledDependencies, setJobsScheduledDependencies] = useState([])

	// const [events, setEvents] = useState([]);
	// const [dependencies, setDependencies] = useState([]);

	const [productionsGroup, setProductionsGroup] = useState<GroupedJobsData[]>()
	// const showEditor = (eventRecord) => {
	// 	setEventRecord(eventRecord)
	// 	showPopup(true)
	// }

	// const [showProductionsGroup, setShowProductionsGroup] = useState<GroupedJobsData[]>()
	const [searchWO, setSearchWO] = useState('')
	const [searchSO, setSearchSO] = useState('')

	const [btnAction, setBtnAction] = useState('')
	const [scheduleStatus, setScheduleStatus] = useState('')

	const getRandomColor = () => {
		const brightness = 1.2
		const rgb = [Math.random() * 256, Math.random() * 256, Math.random() * 256]
		const mix = [brightness * 51, brightness * 51, brightness * 51] //51 => 255/5
		const mixedrgb = [rgb[0] + mix[0], rgb[1] + mix[1], rgb[2] + mix[2]].map(
			(x) => {
				return Math.round(x / 2.0)
			}
		)

		const hexValues = mixedrgb.map((value) =>
			value.toString(16).padStart(2, '0')
		)
		const hexColor = `#${hexValues.join('')}`
		return hexColor
	}
	const getResourceId = (StageName: string) => {
		const resource = resources.find((resource) => resource.name === StageName)
		return resource?.id
	}
	const generateEvent = (
		item: ProductionEntry | any,
		bgColor: string
	): EventEntry => {
		const {
			DocNum,
			Resource,
			StageName,
			ProdCode,
			Duration,
			SeqNum,
			Color,
			ProdName,
			Id,
			Status,
			OriginNum,
			DueDate,
			ShipType,
			bg,
			SegDuration,
			WhsCode,
			hide,
			RelatedSchedule,
		} = item
		return {
			id: `${Id} - ${DocNum}-${Resource}-${SeqNum}-${StageName}`,
			Id: Id,
			resourceId: getResourceId(StageName),
			name: DocNum,
			cls: '',
			draggable: Status === 'P',
			resizable: false,
			startDate: item.StartDate ?? item.startDate,
			endDate: item.EndDate ?? item.endDate,
			bg: Color ?? bgColor ?? bg,
			Duration: Duration,
			ProdCode: ProdCode,
			ProdName: ProdName,
			DocNum: DocNum,
			Resource: Resource,
			SeqNum: SeqNum,
			StageName: StageName,
			Status: Status,
			OriginNum: OriginNum,
			DueDate: DueDate,
			ShipType: ShipType,
			SegDuration: SegDuration,
			WhsCode: WhsCode ?? warehouse,
			hide: hide,
			RelatedSchedule: RelatedSchedule,
		}
	}

	const hideEditor = useCallback(() => {
		// If isCreating is still true, user clicked cancel
		if (eventRecord.isCreating) {
			eventStore.remove(eventRecord)
			eventRecord.isCreating = false
		}
		setEventRecord(null)
		showPopup(false)
	}, [eventRecord, eventStore])

	const [listeners] = useState({
		beforeEventEdit: (source) => {
			// source.eventRecord.resourceId = source.resourceRecord.id;
			// showEditor(source.eventRecord);
			// return false;
		},
	})
	useEffect(() => {
		const { eventStore, resourceStore, dependencyStore } =
			schedulerRef?.current?.instance
		setEventStore(eventStore)
		setResourceStore(resourceStore)
		setDependencyStore(dependencyStore)
		schedulerRef.current.instance.scrollToDate(
			dayjs().add(-1, 'day').toDate(),
			{
				block: 'start',
				animate: 200,
			}
		)
		schedulerRef.current.instance.resourceStore.sort({
			field: 'value',
			ascending: true,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [schedulerRef?.current?.instance])

	const getWarehouseData = async () => {
		try {
			const res = await fetcher.get('/warehouse')
			const data: Warehouse[] = res.data
			setWarehouses(
				data.map((warehouse) => ({
					value: warehouse.WhsCode,
					label: `${warehouse.WhsName} (${warehouse.WhsCode})`,
				}))
			)
		} catch (error) {
			throw handleFetcherError(error)
		}
	}

	const getResourcesData = async (params) => {
		try {
			const res = await fetcher.get('/stage', {
				params: params,
			})
			const data: Stage[] = res.data
			setResource(
				data.map((stage) => ({
					id: stage.StageId,
					name: stage.StageName,
					value: stage.SortOrder,
				}))
			)
		} catch (error) {
			throw handleFetcherError(error)
		}
	}

	const getProductionData = async (params) => {
		try {
			const res = await fetcher.get('/production', {
				params: params,
			})
			const data: ProductionEntry[] = res.data
			const groupedData: GroupedJobsData[] = Object.values(
				data.reduce((acc, item) => {
					const { DocNum, ProdCode, ProdName, OriginNum, DueDate, ShipType } =
						item
					const color = getRandomColor()
					return {
						...acc,
						[DocNum]: {
							value: DocNum,
							originNum: OriginNum,
							label: (
								<div>
									<div>{`WO: ${DocNum}, SKU: ${ProdCode}, ${ProdName}`}</div>
									<div>
										{OriginNum && <span>{`SO: ${OriginNum}`}</span>}
										{DueDate && (
											<span>{`, Due Dt: ${dayjs(DueDate).format(
												'MM/DD/YYYY'
											)}`}</span>
										)}
										{ShipType && <span>{`, Shipping: ${ShipType}`}</span>}
									</div>
								</div>
							),
							color: acc[DocNum]?.color ?? color,
							children: [
								...(acc[DocNum]?.children || []),
								generateEvent(item, acc[DocNum]?.color ?? color),
							],
						},
					}
				}, {})
			)
			setProductionsGroup(groupedData)
			// setShowProductionsGroup(groupedData)
		} catch (error) {
			throw handleFetcherError(error)
		}
	}

	const getJobsScheduledData = async (
		eventStore: EventStore,
		dependencyStore: DependencyStore,
		params: any
	) => {
		try {
			setReady(false)
			const res = await fetcher.get('schedule', {
				params: params,
			})
			const data: Schedule[] = res.data?.filter((item: any) => !item.hide)
			const groupedData: GroupedJobsData[] = Object.values(
				data.reduce((acc, item) => {
					const { DocNum, ProdCode, StartDate, OriginNum, DueDate, ShipType } =
						item
					const color = getRandomColor()
					return {
						...acc,
						[DocNum]: {
							value: DocNum,
							label: (
								<div>
									<div>{`WO: ${DocNum}, SKU: ${ProdCode}, ${dayjs(
										StartDate
									).format('MM/DD/YYYY')}`}</div>
									<div>
										{OriginNum && <span>{`SO: ${OriginNum}`}</span>}
										{DueDate && (
											<span>{`, Due Dt: ${dayjs(DueDate).format(
												'MM/DD/YYYY'
											)}`}</span>
										)}
										{ShipType && <span>{`, Shipping: ${ShipType}`}</span>}
									</div>
								</div>
							),
							color: acc[DocNum]?.color ?? color,
							items: [
								...(acc[DocNum]?.items || []),
								generateEvent(item, acc[DocNum]?.color ?? color),
							],
						},
					}
				}, {})
			)
			const dependencies = []
			groupedData.map((gr) => {
				gr.items.map((item, idx) => {
					if (idx - 1 !== -1) {
						dependencies.push({
							id: item?.id,
							to: item?.id,
							from: gr.items[idx - 1]?.id,
						})
					}
					return item
				})
				return gr
			})
			setJobsScheduled([...groupedData])
			eventStore.data = groupedData.flatMap((item) => item.items)
			await eventStore.project.commitAsync()
			// dependencies.forEach((dependency) => {
			// 	dependencyStore.add(dependency)
			// })
			await dependencyStore.project.commitAsync()
			setReady(true)
		} catch (error) {
			throw handleFetcherError(error)
		}
	}

	const getJobsUnScheduledData = async (params: any) => {
		try {
			const res = await fetcher.get('schedule', {
				params: params,
			})
			const data: Schedule[] = res.data?.filter((item: any) => !item.hide)
			const groupedData: GroupedJobsData[] = Object.values(
				data.reduce((acc, item) => {
					const { DocNum, ProdCode, StartDate, OriginNum, DueDate, ShipType } =
						item
					const color = getRandomColor()
					return {
						...acc,
						[DocNum]: {
							value: DocNum,
							label: (
								<div>
									<div>{`WO: ${DocNum}, SKU: ${ProdCode}, ${dayjs(
										StartDate
									).format('MM/DD/YYYY')}`}</div>
									<div>
										{OriginNum && <span>{`SO: ${OriginNum}`}</span>}
										{DueDate && (
											<span>{`, Due Dt: ${dayjs(DueDate).format(
												'MM/DD/YYYY'
											)}`}</span>
										)}
										{ShipType && <span>{`, Shipping: ${ShipType}`}</span>}
									</div>
								</div>
							),
							color: acc[DocNum]?.color ?? color,
							items: [
								...(acc[DocNum]?.items || []),
								generateEvent(item, acc[DocNum]?.color ?? color),
							],
						},
					}
				}, {})
			)
			// const dependencies = []
			// groupedData.map((gr) => {
			// 	gr.items.map((item, idx) => {
			// 		if (idx - 1 !== -1) {
			// 			dependencies.push({
			// 				id: item?.id,
			// 				to: item?.id,
			// 				from: gr.items[idx - 1]?.id,
			// 			})
			// 		}
			// 		return item
			// 	})
			// 	return gr
			// })
			setJobsUnScheduled(
				groupedData
					.flatMap((item) => item.items)
					.map((event) => ({
						...event,
						duration: event.Duration,
					}))
			)
		} catch (error) {
			throw handleFetcherError(error)
		}
	}

	const getJobsSplitUnScheduledData = async (params: any) => {
		try {
			const res = await fetcher.get('schedule', {
				params: params,
			})
			const data: Schedule[] = res.data?.filter((item: any) => !item.hide)
			const groupedData: GroupedJobsData[] = Object.values(
				data.reduce((acc, item) => {
					const { DocNum, ProdCode, StartDate, OriginNum, DueDate, ShipType } =
						item
					const color = getRandomColor()
					return {
						...acc,
						[DocNum]: {
							value: DocNum,
							label: (
								<div>
									<div>{`WO: ${DocNum}, SKU: ${ProdCode}, ${dayjs(
										StartDate
									).format('MM/DD/YYYY')}`}</div>
									<div>
										{OriginNum && <span>{`SO: ${OriginNum}`}</span>}
										{DueDate && (
											<span>{`, Due Dt: ${dayjs(DueDate).format(
												'MM/DD/YYYY'
											)}`}</span>
										)}
										{ShipType && <span>{`, Shipping: ${ShipType}`}</span>}
									</div>
								</div>
							),
							color: acc[DocNum]?.color ?? color,
							items: [
								...(acc[DocNum]?.items || []),
								generateEvent(item, acc[DocNum]?.color ?? color),
							],
						},
					}
				}, {})
			)
			setJobsSplitUnScheduled(
				groupedData
					.flatMap((item) => item.items)
					.map((event) => ({
						...event,
						duration: event.Duration,
					}))
			)
		} catch (error) {
			throw handleFetcherError(error)
		}
	}

	const updateScheduledData = async (data: EventModel) => {
		try {
			const event = data.toJSON() as EventEntry
			if (!event.Id) return
			await fetcher.patch('schedule/' + encodeURIComponent(event.Id), event)
			setScheduleStatus('update success')
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		void getWarehouseData()
	}, [])

	useEffect(() => {
		if (startDate && endDate) {
			getJobsUnScheduledData({
				WhsCode: warehouse,
				type: 'un-schedule',
			})
			getJobsSplitUnScheduledData({
				WhsCode: warehouse,
				type: 'split-schedule',
				startDate: startDate,
				endDate: endDate,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse, startDate, endDate])

	useEffect(() => {
		void getResourcesData({
			WhsCode: warehouse,
			Active: true,
		})
	}, [warehouse])

	useEffect(() => {
		if (warehouse && resources.length > 0) {
			if (startDate && endDate) {
				getProductionData({
					WhsCode: warehouse,
				})
				if (eventStore && dependencyStore) {
					getJobsScheduledData(eventStore, dependencyStore, {
						WhsCode: warehouse,
						type: 'schedule',
						startDate: startDate,
						endDate: endDate,
					})
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse, resources, eventStore, dependencyStore, startDate, endDate])

	useEffect(() => {
		if (date) {
			let currentDate = date.toDate()
			if (isWeekend(currentDate)) {
				currentDate = nextMonday(new Date(currentDate))
			}
			let startDate = new Date(
				dayjs(currentDate).add(-1, 'day').toDate().setHours(7, 0, 0, 0)
			)
			let endDate = new Date(
				dayjs(currentDate).add(1, 'day').toDate().setHours(17, 0, 0, 0)
			)

			if (isMonday(currentDate)) {
				startDate = new Date(
					dayjs(currentDate).add(-3, 'day').toDate().setHours(7, 0, 0, 0)
				)
				endDate = new Date(
					dayjs(currentDate).add(1, 'day').toDate().setHours(17, 0, 0, 0)
				)
			}

			if (isFriday(currentDate)) {
				startDate = new Date(
					dayjs(currentDate).add(-1, 'day').toDate().setHours(7, 0, 0, 0)
				)
				endDate = new Date(
					dayjs(currentDate).add(3, 'day').toDate().setHours(17, 0, 0, 0)
				)
			}

			setConfig((prev) => ({
				...prev,
				startDate: startDate,
			}))
			setTimeout(() => {
				setConfig((prev) => ({
					...prev,
					endDate: endDate,
				}))
			}, 0)

			setTimeout(() => {
				schedulerRef.current?.instance.scrollToDate(
					new Date(new Date(currentDate.setHours(7, 0, 0, 0))),
					{
						block: 'start',
						animate: 200,
					}
				)
			}, 0)
			setStartDate(format(startDate, 'yyyy-MM-dd'))
			setEndDate(format(endDate, 'yyyy-MM-dd'))
		}
	}, [date])

	useEffect(() => {
		if (startDate && endDate) {
			if (scheduleStatus === 'update success') {
				getJobsScheduledData(eventStore, dependencyStore, {
					WhsCode: warehouse,
					type: 'schedule',
					startDate: startDate,
					endDate: endDate,
				})
				getJobsUnScheduledData({
					WhsCode: warehouse,
					type: 'un-schedule',
				})
				setScheduleStatus('')
			}
			if (scheduleStatus === 'add success') {
				getJobsUnScheduledData({ WhsCode: warehouse, type: 'un-schedule' })
				setScheduleStatus('')
			}
			if (scheduleStatus === 'delete success') {
				getProductionData({
					WhsCode: warehouse,
				})
				getJobsScheduledData(eventStore, dependencyStore, {
					WhsCode: warehouse,
					type: 'schedule',
					startDate: startDate,
					endDate: endDate,
				})
				setScheduleStatus('')
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dependencyStore, endDate, startDate])

	const handleOnDataChange = ({ store, action, records }) => {
		// console.log(`${store.id} changed. The action was: ${action}. Changed records: `, records);
		// Your sync data logic comes here
		if (ready) {
			if (store.id === 'events' && action === 'update') {
				if (records[0].Id) {
					// updateScheduledData(records[0])
				}
			}
		}
	}

	// useEffect(() => {
	// 	let filterList = [...(projectListing ?? [])]
	//   setShowProductionsGroup(productionsGroup?.filter((item) => item.value.toString().includes(searchWO)))
	//   // setShowProductionsGroup(productionsGroup?.filter((item) => item.value.toString().includes(searchSO)))
	// }, [productionsGroup, searchWO, searchSO])
	const showProductionsGroup = useMemo(() => {
		const searchQueryWO = searchWO.trim()
		const searchQuerySO = searchSO.trim()

		let filterList = [...(productionsGroup ?? [])]

		if (searchQueryWO) {
			filterList = filterList.filter((item) => {
				return item.value.toString().includes(searchQueryWO)
			})
		}

		if (searchQuerySO) {
			filterList = filterList.filter((item) => {
				return item.originNum?.toString().includes(searchQuerySO)
			})
		}

		return filterList
	}, [productionsGroup, searchWO, searchSO])

	// Reschedules the overlapping events if the button is pressed
	const onEventStoreUpdate = useCallback(
		({
			record,
			source: eventStore,
		}: {
			record: Task
			source: TaskStore
		}): void => {
			if (!ready) return
			updateScheduledData(record)
		},
		[ready]
	)

	// Reschedules the overlapping events if the button is pressed
	const onEventStoreAdd = useCallback(
		({
			records,
			source: eventStore,
		}: {
			records: Task[]
			source: TaskStore
		}): void => {
			if (!ready) return
			if ((records[0] as any).Id) {
				updateScheduledData(records[0])
			}
		},
		[ready]
	)

	// Event context menu feature config. Adds `Unassign` menu item
	// const [eventMenuFeature] = useState({
	// 	items: {
	// 		// custom item with inline handler
	// 		unassign: {
	// 			text: 'Unassign',
	// 			icon: 'b-fa b-fa-user-times',
	// 			weight: 200,
	// 			onItem: ({
	// 				eventRecord,
	// 				resourceRecord,
	// 			}: {
	// 				eventRecord: EventModel | any
	// 				resourceRecord: ResourceModel
	// 			}) => {
	// 				eventRecord.setStartEndDate(null, null)
	// 				return eventRecord.unassign(resourceRecord)
	// 			},
	// 		},
	// 	},
	// })

	// Set onUpdate and onAdd functions to eventStore
	useEffect(() => {
		const eventStore = schedulerRef.current.instance.eventStore
		Object.assign(eventStore, {
			onUpdate: onEventStoreUpdate,
			onAdd: onEventStoreAdd,
		})
	}, [onEventStoreAdd, onEventStoreUpdate])

	useEffect(() => {
		const drag = new Drag({
			grid: gridRef.current.instance,
			schedule: schedulerRef.current.instance,
			constrain: false,
			outerElement: gridRef.current.instance.element,
			jobsSplitUnScheduled: jobsSplitUnScheduled,
		})
		return () => {
			drag.destroy()
		}
	}, [jobsSplitUnScheduled])
	return (
		<div className="w-full h-full">
			{isAdmin && <FloatButton onClick={showDrawer} icon={<LeftOutlined />} />}
			<>
				{popupShown ? (
					<Popup
						text="Popup text"
						closePopup={hideEditor}
						eventRecord={eventRecord}
						eventStore={eventStore}
						dependencyStore={dependencyStore}
						resourceStore={resourceStore}
					/>
				) : null}
			</>
			<div className="flex justify-center items-center gap-1 my-2 relative">
				<div className="flex gap-4">
					<div>
						<Button
							icon={<LeftOutlined />}
							onClick={() => {
								setDate((prev) => dayjs(prev).add(-1, 'day'))
							}}
						/>
						<DatePicker
							value={date}
							onChange={(_date, dateString) => {
								setDate(dayjs(dateString))
							}}
							allowClear={false}
						/>
						<Button
							icon={<RightOutlined />}
							onClick={() => {
								setDate((prev) => dayjs(prev).add(1, 'day'))
							}}
						/>
					</div>
					<div>
						<span className="mr-1">Select plant:</span>
						<Select
							options={warehouses}
							style={{
								width: 250,
							}}
							value={warehouse}
							onChange={(value) => {
								setReady(false)
								setWarehouse(value)
							}}
							placeholder={'Select plant'}
							bordered
							status="error"
							listHeight={512}
						/>
					</div>
				</div>

				<div className="absolute right-4 top-0">
					<span className="mr-1">Select theme:</span>
					<Select
						options={data.themes}
						style={{
							width: 250,
						}}
						value={theme}
						onChange={(value) => {
							setTheme(value)
							DomHelper.setTheme(value)
						}}
						bordered
					/>
				</div>
			</div>
			<div id="main" className={'h-[calc(100%-3rem)]'}>
				<UnplannedGrid
					gridRef={gridRef}
					schedulerRef={schedulerRef}
					isAdmin={isAdmin}
					data={jobsUnScheduled}
				/>
				<BryntumSplitter />
				{isAdmin ? (
					<BryntumScheduler
						ref={schedulerRef}
						{...config}
						resources={resources}
						listeners={listeners}
						onDataChange={handleOnDataChange}
						eventDragFeature={{
							showTooltip: true,
						}}
					/>
				) : (
					<BryntumScheduler
						ref={schedulerRef}
						{...config}
						features={{ cellMenu: false }}
						resources={resources}
						listeners={listeners}
						eventMenuFeature={
							isAdmin
								? undefined
								: {
										items: {
											copyEvent: false,
											cutEvent: false,
											deleteEvent: false,
											editEvent: false,
										},
								  }
						}
						eventDragFeature={{
							disabled: isAdmin ? false : true,
							showTooltip: true,
						}}
						eventEditFeature={{
							readOnly: isAdmin ? false : true,
						}}
					/>
				)}
			</div>

			<Drawer
				title="Schedule Table"
				placement={'right'}
				closable={true}
				onClose={onClose}
				open={openScheduleTable}
				key={'right'}
				width={1200}
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginBottom: '16px',
						gap: '16px',
					}}
				>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: '16px',
						}}
					>
						<div>
							<Button
								icon={<LeftOutlined />}
								onClick={() => {
									setReady(false)
									setDate((prev) => dayjs(prev).add(-1, 'day'))
								}}
							/>
							<DatePicker
								value={date}
								onChange={(_date, dateString) => {
									setReady(false)
									setDate(dayjs(dateString))
								}}
								allowClear={false}
							/>
							<Button
								icon={<RightOutlined />}
								onClick={() => {
									setReady(false)
									setDate((prev) => dayjs(prev).add(1, 'day'))
								}}
							/>
						</div>
						{warehouse && (
							<div>
								<div className="flex justify-center items-center gap-1 my-2">
									<span>Select plant:</span>
									<Select
										options={warehouses}
										style={{
											width: 250,
										}}
										value={warehouse}
										onChange={(value) => {
											setWarehouse(value)
										}}
										placeholder={'Select plant'}
										bordered
										status="error"
										listHeight={512}
									/>
								</div>
							</div>
						)}
						<div className="flex justify-center items-center gap-1 my-2">
							<span className="flex-1">Work Order:</span>
							<Input
								value={searchWO}
								onChange={(e) => {
									setSearchWO(e.target.value)
								}}
								style={{
									width: 100,
								}}
							/>
						</div>
						<div className="flex justify-center items-center gap-1 my-2">
							<span className="flex-1">SO:</span>
							<Input
								value={searchSO}
								onChange={(e) => {
									setSearchSO(e.target.value)
								}}
								style={{
									width: 100,
								}}
							/>
						</div>
					</div>
					<div>
						<Button onClick={() => setBtnAction('btn_schedule_jobs')}>
							Ready to Schedule
						</Button>
					</div>
				</div>

				<div
					style={{
						height: '100%',
						borderTop: '1px solid black',
					}}
				>
					<JobScheduleTable
						date={date}
						group={showProductionsGroup}
						// resources={resources}
						eventStore={eventStore}
						dependencyStore={dependencyStore}
						jobsScheduled={jobsScheduled}
						btnAction={btnAction}
						setBtnAction={setBtnAction}
						setScheduleStatus={setScheduleStatus}
						setReady={setReady}
					/>
				</div>
			</Drawer>
		</div>
	)
}

export default withAuth(Scheduler)
