/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react'
import { Form, Table, Typography, TimePicker } from 'antd'
import { fetcher, handleFetcherError } from 'src/fetcher'
import dayjs from 'dayjs'
import withAuth from 'src/HOC/withAuth'

interface Item {
	key: string
	WhsCode: string
	StartTime: string
	EndTime: string
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	editing: boolean
	dataIndex: string
	title: any
	inputType: 'number' | 'text' | 'dateTime'
	record: Item
	index: number
	children: React.ReactNode
}

const EditableCell: React.FC<EditableCellProps> = ({
	editing,
	dataIndex,
	title,
	inputType,
	record,
	index,
	children,
	...restProps
}) => {
	return (
		<td key={index} {...restProps}>
			{editing ? (
				<Form.Item name={dataIndex}>
					<TimePicker
						defaultValue={dayjs(record[dataIndex], 'hh:mm:ss')}
						// format={'HH:mm'}
					/>
				</Form.Item>
			) : (
				children
			)}
		</td>
	)
}
interface IProps {
	user: {
		Id: number
		Active: boolean
		Name: string
		Type: {
			id: number
			name: string
		}
	}
}
const Component: React.FC<IProps> = ({ user }) => {
	const [form] = Form.useForm()
	const [data, setData] = useState<Item[]>()
	const [editingKey, setEditingKey] = useState('')
	const isAdmin = useMemo(
		() => user?.Type?.name === 'Production manager',
		[user?.Type?.name]
	)
	console.log(user,isAdmin)
	const isEditing = (record: Item) => record.key === editingKey

	const edit = (record: Partial<Item> & { key: React.Key }) => {
		form.setFieldsValue({ StartTime: '', EndTime: '', ...record })
		setEditingKey(record.key)
	}

	const cancel = () => {
		setEditingKey('')
	}

	const save = async (key: React.Key, WhsCode: string) => {
		try {
			const row = (await form.validateFields()) as Item

			const newData = [...data]
			const index = newData.findIndex((item) => key === item.key)
			let newRow = {
				...row,
				StartTime: `${formatTimeString(
					dayjs(row.StartTime).get('hour')
				)}:${formatTimeString(
					dayjs(row.StartTime).get('minute')
				)}:${formatTimeString(dayjs(row.StartTime).get('second'))}`,
				EndTime: `${formatTimeString(
					dayjs(row.EndTime).get('hour')
				)}:${formatTimeString(
					dayjs(row.EndTime).get('minute')
				)}:${formatTimeString(dayjs(row.EndTime).get('second'))}`,
			}

			if (index > -1) {
				const item = newData[index]
				newRow = {
					...row,
					StartTime: row.StartTime
						? `${formatTimeString(
								dayjs(row.StartTime).get('hour')
						  )}:${formatTimeString(
								dayjs(row.StartTime).get('minute')
						  )}:${formatTimeString(dayjs(row.StartTime).get('second'))}`
						: item.StartTime,
					EndTime: row.EndTime
						? `${formatTimeString(
								dayjs(row.EndTime).get('hour')
						  )}:${formatTimeString(
								dayjs(row.EndTime).get('minute')
						  )}:${formatTimeString(dayjs(row.EndTime).get('second'))}`
						: item.EndTime,
				}

				newData.splice(index, 1, {
					...item,
					...newRow,
				})
				setData(newData)
				setEditingKey('')
			} else {
				newData.push(newRow)
				setData(newData)
				setEditingKey('')
			}
			updateSingleStageData({
				whsCode: WhsCode,
				body: {
					StartTime: newRow.StartTime,
					EndTime: newRow.EndTime,
				},
			})
		} catch (errInfo) {
			console.log('Validate Failed:', errInfo)
		}
	}

	const columns = [
		{
			title: 'WhsCode',
			dataIndex: 'WhsCode',
			width: '20%',
			editable: isAdmin ? true : false,
		},
		{
			title: 'Start Time',
			dataIndex: 'StartTime',
			width: '35%',
			editable: isAdmin ? true : false,
		},
		{
			title: 'End Time',
			dataIndex: 'EndTime',
			width: '35%',
			editable: isAdmin ? true : false,
		},
		{
			title: 'operation',
			dataIndex: 'operation',
			render: (_: any, record: Item) => {
				const editable = isEditing(record)
				return isAdmin ? (
					editable ? (
						<span>
							<Typography.Link
								onClick={() => save(record.key, record.WhsCode)}
								style={{ marginRight: 8 }}
							>
								Save
							</Typography.Link>
							<a href="" onClick={cancel}>
								Cancel
							</a>
						</span>
					) : (
						<Typography.Link
							disabled={editingKey !== ''}
							onClick={() => edit(record)}
						>
							Edit
						</Typography.Link>
					)
				) : (
					<></>
				)
			},
		},
	]

	const mergedColumns = columns.map((col) => {
		if (!col.editable) {
			return col
		}
		return {
			...col,
			onCell: (record: Item) => ({
				record,
				inputType: 'dateTime',
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record),
			}),
		}
	})

	const formatTimeString = (time: number) => {
		return time >= 0 && time < 10 ? `0${time}` : `${time}`
	}

	const getTimeOnly = (dateTimeString: string | Date) => {
		const dateObject = new Date(dateTimeString)
		return dateObject.toISOString().slice(11, 19)
	}

	const fetchPlantHoours = async (params?: any) => {
		try {
			const res = await fetcher.get('plant-hours', {
				params: params,
			})
			const data: any[] = res.data

			setData(
				data.map((ph) => {
					return {
						...ph,
						key: ph.WhsCode,
						StartTime: getTimeOnly(ph.StartTime),
						EndTime: getTimeOnly(ph.EndTime),
					}
				})
			)
		} catch (error) {
			throw handleFetcherError(error)
		}
	}

	const updateSingleStageData = async (data: {
		whsCode: string
		body: {
			StartTime: string
			EndTime: string
		}
	}) => {
		try {
			await fetcher.patch(
				'plant-hours/' + encodeURIComponent(data.whsCode),
				data.body
			)
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchPlantHoours()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Form
			form={form}
			component={false}
			initialValues={[
				{
					WhsCode: '01',
					StartTime: new Date(),
					EndTime: new Date(),
				},
			]}
		>
			<Table
				components={{
					body: {
						cell: EditableCell,
					},
				}}
				bordered
				dataSource={data}
				columns={mergedColumns}
				rowClassName="editable-row"
				pagination={{
					onChange: cancel,
				}}
			/>
		</Form>
	)
}

export default withAuth(Component)
