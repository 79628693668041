/**
 * Taken from the vanilla dragfromgrid example
 */
import {
	DateHelper,
	DragHelper,
	DomHelper,
	WidgetHelper,
	Tooltip,
	ScrollManager,
	Model,
	EventTooltip,
	EventDrag,
	ResourceModel,
	DragHelperConfig,
} from '@bryntum/scheduler'
import { Grid, Scheduler } from '@bryntum/scheduler'
import Task from './Task'
import TaskStore from './TaskStore'
import dayjs from 'dayjs'
import {
	addDays,
	differenceInMinutes,
	isAfter,
	isWeekend,
	nextMonday,
} from 'date-fns'

type DragConfig = Partial<DragHelperConfig> & {
	grid: Grid
	schedule: Scheduler
	outerElement: HTMLElement
	jobsSplitUnScheduled: any[]
}

export const getNextWorkingDate = (date?: Date): Date => {
	date = date ?? new Date()

	let nextWorkingDate = new Date(addDays(date, 1).setHours(7, 0, 0, 0))
	if (isWeekend(nextWorkingDate)) {
		nextWorkingDate = new Date(nextMonday(nextWorkingDate).setHours(7, 0, 0, 0))
	}

	return nextWorkingDate
}

export default class Drag extends DragHelper {
	static get configurable() {
		return {
			callOnFunctions: true,
			// Don't drag the actual row element, clone it
			cloneTarget: true,
			// We size the cloned element manually
			autoSizeClonedTarget: false,
			// Only allow drops on the schedule area
			dropTargetSelector: '.b-timeline-subgrid',
			// Only allow drag of row elements inside on the unplanned grid
			targetSelector: '.b-grid-row:not(.b-group-row)',
		}
	}

	public scrollManager!: ScrollManager
	public grid: Grid
	public outerElement: HTMLElement
	public jobsSplitUnScheduled: any[]

	private tip: Tooltip | undefined
	private _schedule!: Scheduler

	// We need this construction for TypeScript
	// Also, we cannot use `me` as variable for `this`
	constructor(config: DragConfig) {
		super(config)
		this.grid = config.grid
		this.schedule = config.schedule
		this.outerElement = config.outerElement
		this.jobsSplitUnScheduled = config.jobsSplitUnScheduled
	}

	afterConstruct(): void {
		// Configure DragHelper with schedule's scrollManager to allow scrolling while dragging
		this.scrollManager = this.schedule.scrollManager as ScrollManager
	}

	createProxy(element: HTMLElement): HTMLDivElement {
		const proxy = document.createElement('div')
		const { schedule } = this
		const task = this.grid.getRecordFromElement(element) as Task
		const durationInPx = schedule.timeAxisViewModel.getDistanceForDuration(
			task.getDurationMS ? task.getDurationMS() : task.durationMS
		)
		task.Duration =
			(task.getDurationMS ? task.getDurationMS() : task.durationMS) / 60000
		// Fake an event bar
		proxy.classList.add(
			'b-sch-event-wrap',
			'b-sch-event',
			'b-unassigned-class',
			`b-sch-${schedule.mode}`
		)
		proxy.innerHTML = `<div class="b-sch-event b-has-content b-sch-event-withicon">
            <div class="b-sch-event-content">
                <i class="${task.get('iconCls')}"></i> ${task.get('name')}
            </div>
        </div>`

		if (schedule.mode === 'horizontal') {
			proxy.style.height = `${
				schedule.rowHeight - 2 * schedule.resourceMargin
			}px`
			proxy.style.width = `${durationInPx}px`
		} else {
			proxy.style.height = `${durationInPx}px`
			proxy.style.width = `${schedule.resourceColumnWidth}px`
		}

		return proxy
	}

	onDragStart = ({
		context,
	}: {
		context: { task: Model; grabbed: HTMLElement; element: HTMLElement }
	}) => {
		const me = this
		const { schedule } = me
		const {
			eventTooltip,
			eventDrag,
		}: { eventTooltip: EventTooltip; eventDrag: EventDrag } = schedule.features

		// save a reference to the task so we can access it later
		context.task = me.grid.getRecordFromElement(context.grabbed)

		// Prevent tooltips from showing while dragging
		eventTooltip.disabled = true

		schedule.enableScrollingCloseToEdges(schedule.timeAxisSubGrid)

		// @ts-ignore
		if (eventDrag.showTooltip && !me.tip) {
			me.tip = new Tooltip({
				align: 'b-t',
				// @ts-ignore
				clippedBy: [schedule.timeAxisSubGridElement, schedule.bodyContainer],
				forElement: context.element,
				cls: 'b-popup b-sch-event-tooltip',
			})
		}
	}

	onDrag = ({
		event,
		context,
	}: {
		event: MouseEvent
		context: {
			task: Task | any
			element: HTMLElement
			valid: Boolean
			target: HTMLElement
			resource: ResourceModel
		}
	}) => {
		const me = this
		const { schedule } = me
		const { task } = context
		const coordinate = DomHelper[
			`getTranslate${schedule.mode === 'horizontal' ? 'X' : 'Y'}`
		](context.element)
		const startDate = schedule.getDateFromCoordinate(coordinate, 'round', false)
		const endDate =
			startDate && DateHelper.add(startDate, task.duration, task.durationUnit)
		// Coordinates required when used in vertical mode, since it does not use actual columns
		const resource =
			context.target &&
			schedule.resolveResourceRecord(context.target, [
				event.offsetX,
				event.offsetY,
			])

		// Don't allow drops anywhere, only allow drops if the drop is on the timeaxis and on top of a Resource
		context.valid =
			Boolean(startDate && resource) &&
			// @ts-ignore
			(schedule.allowOverlap ||
				schedule.isDateRangeAvailable(startDate, endDate, null, resource))

		// Save reference to resource so we can use it in onTaskDrop
		context.resource = resource

		if (me.tip && context.valid) {
			const dateFormat = schedule.displayDateFormat,
				formattedStartDate = DateHelper.format(startDate, dateFormat),
				formattedEndDate = DateHelper.format(endDate, dateFormat)

			me.tip.html = `
                <div class="b-sch-event-title">${task.name}</div>
            <p>Item: ${task.ProdCode}</p>
            <p>Res: ${task.Resource}</p>
            <p>Mins: ${task.SegDuration}</p>
            ${task.OriginNum ? `<p>SO: ${task.OriginNum}</p>` : ''} 
            ${
							task.DueDate
								? `<p>Due Dt. ${dayjs(task.DueDate).format('MM/DD/YYYY')}</p>`
								: ''
						} 
            ${task.ShipType ? `<p>Ship Type: ${task.ShipType}</p>` : ''} 
                <div class="b-sch-tooltip-startdate">Starts: ${formattedStartDate}</div>
                <div class="b-sch-tooltip-enddate">Ends: ${formattedEndDate}</div>
            `
			me.tip.showBy(context.element)
		} else if (me.tip) {
			me.tip.hide()
		}
	}

	// Drop callback after a mouse up, take action and transfer the unplanned task to the real EventStore (if it's valid)
	onDrop = ({
		context,
		event,
	}: {
		context: {
			task: Task | any
			target: HTMLElement
			resource: ResourceModel
			valid: Boolean
			element: HTMLElement
		}
		event: MouseEvent
	}) => {
		const me = this
		const { schedule, jobsSplitUnScheduled } = me
		const { task, target, resource, valid, element } = context
		//   if (resource.name !== task.StageName) {
		//     return WidgetHelper.toast(`Please drop right Resource`)
		//  }
		me.tip?.hide()

		schedule.disableScrollingCloseToEdges(me.schedule.timeAxisSubGrid)

		// If drop was done in a valid location, set the startDate and transfer the task to the Scheduler event store
		if (valid && target) {
			const coordinate =
					DomHelper[
						`getTranslate${schedule.mode === 'horizontal' ? 'X' : 'Y'}`
					](element),
				date = schedule.getDateFromCoordinate(coordinate, 'round', false),
				// Try resolving event record from target element, to determine if drop was on another event
				targetEventRecord = schedule.resolveEventRecord(target)

			if (date) {
				// Remove from grid first so that the data change
				// below does not fire events into the grid.
				;(me.grid.store as TaskStore).remove(task)

				task.startDate = date
				task.duration = task.Duration

				let assignResource = resource.copy()

				const assignResourceIndex = schedule.resources.findIndex(
					(rs) => rs.name === task.StageName
				)
				if (assignResourceIndex !== -1) {
					assignResource = schedule.resources[
						assignResourceIndex
					] as ResourceModel
				}

				const taskEndDate = dayjs(date).add(task.Duration, 'minute').toDate()
				const endWorkTime = new Date(new Date(date).setHours(17, 0, 0, 0))

				const findSplitTask = jobsSplitUnScheduled.find(
					(item) =>
						String((item as any).RelatedSchedule) === String(task.get('Id'))
				)

				if (isAfter(taskEndDate, endWorkTime) && findSplitTask) {
					const splitTask = task.copy(findSplitTask?.id)
					const nextWorkingTime = getNextWorkingDate(date)
					const diff = task.Duration - differenceInMinutes(endWorkTime, date)
					task.duration = differenceInMinutes(endWorkTime, date)
					task.SegDuration = differenceInMinutes(endWorkTime, date)

					splitTask.startDate = nextWorkingTime
					splitTask.endDate = dayjs(nextWorkingTime)
						.add(diff, 'minute')
						.toDate()

					splitTask.id = findSplitTask.id
					splitTask.Id = findSplitTask.Id
					splitTask.duration = diff
					splitTask.SegDuration = diff
					splitTask.assign(assignResource)
					schedule.eventStore.add(splitTask)
				}

				task.assign(assignResource)
				schedule.eventStore.add(task)
			}

			// Dropped on a scheduled event, display toast
			if (targetEventRecord) {
				WidgetHelper.toast(`Dropped on ${targetEventRecord.name}`)
			}
		}

		if (resource) {
			resource.cls = ''
		}

		schedule.features.eventTooltip.disabled = false
	}

	set schedule(schedule: Scheduler) {
		this._schedule = schedule

		// Configure DragHelper with schedule's scrollManager to allow scrolling while dragging
		this.scrollManager = schedule.scrollManager as ScrollManager
	}

	get schedule(): Scheduler {
		return this._schedule
	}

	onDragAbort() {
		this.tip?.hide()
	}
}
