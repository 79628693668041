import React from 'react'
import Cookies from 'js-cookie'
import { fetcher } from 'src/fetcher'

const withAuth = (WrappedComponent) => {
	return class extends React.Component {
		state = {
			user: null,
			isLoading: true,
		}

		async componentDidMount() {
			const token = Cookies.get('auth_token')
			if (token) {
				try {
					const response = await fetcher.get('/auth/me')
					this.setState({ user: response.data, isLoading: false })
				} catch (error) {
					Cookies.remove('auth_token')
					this.setState({ isLoading: false })
					window.location.href = '/login'
				}
			} else {
				window.location.href = '/login'
				this.setState({ isLoading: false })
			}
		}

		render() {
			const { user, isLoading } = this.state
			if (isLoading) {
				return <div>Loading...</div>
			}

			return user ? <WrappedComponent {...this.props} user={user} /> : <></>
		}
	}
}

export default withAuth
