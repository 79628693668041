import { Controller, useForm } from 'react-hook-form'
import z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { fetcher } from 'src/fetcher'
import { useState } from 'react'

const forgotPasswordSchema = z.object({
	username: z.string().min(1),
})

type Status = {
	type: 'SUCCESS' | 'ERROR'
	msg: string
}

const ForgotPassword = () => {
	const [status, setStatus] = useState<Status | null>(null)

	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm({
		resolver: zodResolver(forgotPasswordSchema),
		reValidateMode: 'onChange',
	})

	const onSubmit = async (data) => {
		try {
			await fetcher.post('/auth/forgot-password', {
				username: data.username,
			})
			setStatus({ type: 'SUCCESS', msg: '' })
		} catch (error) {
			setStatus({ type: 'ERROR', msg: error.message ?? 'Reset password fail' })
		}
	}

	return (
		<div className="w-full h-full">
			<section className="bg-gray-50 dark:bg-gray-900">
				<div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
					<div className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
						{/* <img
							className="w-8 h-8 mr-2"
							src="public/favicon.png"
							alt="logo"
						/> */}
						FlashCo
					</div>
					<div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
						{!status && (
							<div className="p-6 space-y-4 md:space-y-6 sm:p-8">
								<h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
									Forgot password
								</h1>

								<form
									className="space-y-4 md:space-y-6"
									onSubmit={handleSubmit(onSubmit)}
								>
									<div>
										<label
											htmlFor="username"
											className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
										>
											Username
										</label>
										<Controller
											name="username"
											control={control}
											render={({ field }) => (
												<input
													{...field}
													type="text"
													name="username"
													id="username"
													className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
													placeholder="Username"
												/>
											)}
										/>
										<div className="text-red-400 mt-2">
											{errors.username?.message as string}
										</div>
									</div>

									<button
										disabled={!isValid}
										type="submit"
										className="w-full bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
									>
										Forgot Password
									</button>
								</form>
							</div>
						)}
						{status && (
							<div className="px-4 py-6">
								{status?.type === 'SUCCESS' && (
									<p className=" text-green-300">
										Please check your email to continue reset password.
									</p>
								)}
								{status?.type === 'ERROR' && <p>{status.msg}</p>}
							</div>
						)}
					</div>
				</div>
			</section>
		</div>
	)
}

export default ForgotPassword
