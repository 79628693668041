import { Button, Select, Switch } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { CheckOutlined } from '@ant-design/icons'
import { fetcher, handleFetcherError } from 'src/fetcher'
import { Warehouse } from '@prisma/client'
import withAuth from 'src/HOC/withAuth'

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)

	return result
}

const grid = 8
const getListStyle = (_isDraggingOver) => ({
	background: '#9E9E9E',
	padding: grid,
	width: 600,
	height: '100%',
})

const getItemStyle = ({ isDragging, draggableStyle, highlight }) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',
	padding: grid * 2,
	margin: `0 0 ${grid}px 0`,

	// change background colour if dragging
	background: '#f8fafc',
	border: isDragging || highlight ? '5px solid #abc9fb' : 'none',

	// styles we need to apply on draggables
	...draggableStyle,
})

const Component = ({user}) => {
	const [items, setItems] = useState<any[]>([])
	const [showSaveButton, setShowSaveButton] = useState(false)
	const [saveMessage, setSaveMessage] = useState('')
	const [warehouses, setWarehouses] = useState([])
	const [warehouse, setWarehouse] = useState('01')
	const isAdmin = useMemo(
		() => user?.Type?.name === 'Production manager',
		[user?.Type?.name]
	)
	const onChange = (checked: boolean, item: any) => {
		handleUpdateSingleStage({
			whsCode: item.WhsCode,
			stageId: item.StageId,
			body: {
				Active: checked,
			},
		})
	}

	const renderItem = (item) => {
		return (
			<div className="flex flex-row align-items-center">
				<span style={{ maxWidth: '300px' }} className="flex-grow-1 w-2/3">
					{item.StageName}
				</span>
				<div className="w-1/3">
					<Switch
						disabled={isAdmin ? false : true}
						className="bg-[#00000040]"
						defaultChecked={item.Active}
						onChange={(checked: boolean) => onChange(checked, item)}
					/>
				</div>
			</div>
		)
	}

	const onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return
		}

		let newItems = reorder(items, result.source.index, result.destination.index)
		newItems = newItems.map((item: any) => ({
			...item,
			SortOrder: newItems.indexOf(item),
		}))
		setItems(newItems)
		if (!showSaveButton) {
			setShowSaveButton(!showSaveButton)
		}
	}

	const handleOnSave = async () => {
		setSaveMessage('Successful')
		setTimeout(() => {
			setShowSaveButton(false)
			setSaveMessage('')
		}, 2000)
		// updateStageData()
		const editedItems = items.map((item) => {
			const { id, ...itemDetails } = item

			return {
				...itemDetails,
			}
		})
		updateStagesData(editedItems)
	}

	const handleUpdateSingleStage = (data: {
		whsCode: string
		stageId: number
		body: {
			Active: boolean
		}
	}) => {
		updateSingleStageData(data)
	}

	const getWarehouseData = async () => {
		try {
			const res = await fetcher.get('/warehouse')
			const data: Warehouse[] = res.data
			setWarehouses(
				data.map((warehouse) => ({
					value: warehouse.WhsCode,
					label: `${warehouse.WhsName} (${warehouse.WhsCode})`,
				}))
			)
		} catch (error) {
			throw handleFetcherError(error)
		}
	}

	const fetchStage = async (params) => {
		try {
			const res = await fetcher.get('stage', {
				params: params,
			})
			const data: any[] = res.data
			setItems(
				data.map((item) => ({
					...item,
					id: item.StageId.toString(),
				}))
			)
		} catch (error) {
			throw handleFetcherError(error)
		}
	}

	const updateStagesData = async (data: any) => {
		try {
			await fetcher.patch('stage', data)
		} catch (error) {
			console.log(error)
		}
	}

	const updateSingleStageData = async (data: {
		whsCode: string
		stageId: number
		body: {
			Active: boolean
		}
	}) => {
		try {
			await fetcher.patch(
				'stage/' +
					encodeURIComponent(data.whsCode) +
					'/' +
					encodeURIComponent(data.stageId),
				data.body
			)
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if (warehouse) {
			fetchStage({ WhsCode: warehouse })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		void getWarehouseData()
	}, [])

	return (
		<div>
			<div>
				<span className="mr-1">Select plant:</span>
				<Select
					options={warehouses}
					style={{
						width: 250,
					}}
					value={warehouse}
					onChange={(value) => {
						// setReady(false)
						setWarehouse(value)
					}}
					placeholder={'Select plant'}
					bordered
					status="error"
					listHeight={512}
				/>
			</div>
			<DragDropContext onDragEnd={onDragEnd}>
				{showSaveButton && isAdmin ? (
					<div
						style={{
							// background: '#E0E0E0',
							width: '80px',
							position: 'relative',
							// height: offsetHeight,
						}}
					>
						<div
							className="sticky flex flex-column justify-content-center align-items-center"
							style={{
								top: '150px',
							}}
						>
							<Button
								shape="circle"
								// className="sticky border-circle w-2rem h-2rem bg-transparent text-green-400 border-green-400"
								// loading={loading}
								icon={<CheckOutlined />}
								color="green"
								onClick={handleOnSave}
							/>
							{saveMessage && <p className="text-green-400">{saveMessage}</p>}
						</div>
					</div>
				) : null}
				<Droppable droppableId="droppable">
					{(provided, snapshot) => (
						<div
							{...provided.droppableProps}
							ref={provided.innerRef}
							style={getListStyle(snapshot.isDraggingOver)}
						>
							{items.map((item, index) => (
								<Draggable key={item.id} draggableId={item.id} index={index}>
									{(provided, snapshot) => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											style={getItemStyle({
												isDragging: snapshot.isDragging,
												draggableStyle: provided.draggableProps.style,
												highlight: false,
											})}
											// id={`project-steps-kanban-${item.id}`}
										>
											{renderItem(item)}
										</div>
									)}
								</Draggable>
							))}

							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</div>
	)
}

export default withAuth(Component)
