import { Controller, useForm } from 'react-hook-form'
import z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { fetcher } from 'src/fetcher'
import { useEffect, useState } from 'react'
import qs from 'qs'
import { jwtDecode } from 'jwt-decode'
import { Link, redirect } from 'react-router-dom'

const resetPasswordSchema = z
	.object({
		password: z.string().min(6),
		confirmPassword: z.string(),
	})
	.superRefine(({ confirmPassword, password }, ctx) => {
		if (confirmPassword !== password) {
			ctx.addIssue({
				code: 'custom',
				message: 'The passwords did not match',
			})
		}
	})

type Status = {
	type: 'SUCCESS' | 'ERROR'
	msg: string
}

const ResetPassword = () => {
	const [token, setToken] = useState<string | null>(null)
	const [status, setStatus] = useState<Status | null>(null)
	useEffect(() => {
		const token = qs.parse(window.location.search.replace('?', ''))?.[
			'verifyToken'
		]
		if (
			token &&
			typeof token === 'string' &&
			jwtDecode(token).exp * 1000 > Date.now()
		) {
			setToken(token)
		} else {
			setStatus({
				type: 'ERROR',
				msg: 'This reset password link expired. Please try it again.',
			})
		}
	}, [])

	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm({
		resolver: zodResolver(resetPasswordSchema),
	})

	const onSubmit = async (data) => {
		try {
			await fetcher.post(
				'/auth/reset-password',
				{
					password: data.password,
				},
				{
					headers: {
						Authorization: `Bear ${token}`,
					},
				}
			)
			setStatus({ type: 'SUCCESS', msg: '' })
		} catch (error) {
			setStatus({ type: 'ERROR', msg: error.message ?? 'Reset password fail' })
		}
	}

	return (
		<div className="w-full h-full">
			<section className="bg-gray-50 dark:bg-gray-900">
				<div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
					<div className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
						{/* <img
							className="w-8 h-8 mr-2"
							src="public/favicon.png"
							alt="logo"
						/> */}
						FlashCo
					</div>
					<div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
						{token && !status && (
							<div className="p-6 space-y-4 md:space-y-6 sm:p-8">
								<h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
									Reset password
								</h1>

								<form
									className="space-y-4 md:space-y-6"
									onSubmit={handleSubmit(onSubmit)}
								>
									<div>
										<label
											htmlFor="password"
											className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
										>
											Password
										</label>
										<Controller
											name="password"
											control={control}
											render={({ field }) => (
												<input
													{...field}
													type="password"
													name="password"
													id="password"
													className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
													placeholder="New password"
												/>
											)}
										/>
										<div className="text-red-400 mt-2">
											{errors.password?.message as string}
										</div>
									</div>

									<div>
										<label
											htmlFor="confirmPassword"
											className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
										>
											Confirm password
										</label>
										<Controller
											name="confirmPassword"
											control={control}
											render={({ field }) => (
												<input
													{...field}
													type="password"
													name="confirmPassword"
													id="confirmPassword"
													className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
													placeholder="Confirm new password"
												/>
											)}
										/>
										<div className="text-red-400 mt-2">
											{errors.confirmPassword?.message as string}
										</div>
									</div>
									{status?.type === 'ERROR' && <p>{status.msg}</p>}
									<button
										disabled={!isValid}
										type="submit"
										className="w-full bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
									>
										Sign in
									</button>
								</form>
							</div>
						)}
						{status && (
							<div className="px-4 py-6 ">
								{status?.type === 'SUCCESS' && (
									<div className="flex gap-2">
										<p>Reset password success.</p>
										<Link className="text-blue-400" to="/login">
											Login
										</Link>
									</div>
								)}
								{status?.type === 'ERROR' && !token && (
									<div className="flex gap-2">
										<p>{status.msg}</p>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</section>
		</div>
	)
}

export default ResetPassword
