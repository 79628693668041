import React, { useEffect, useState, MutableRefObject } from 'react'
import { BryntumGrid, BryntumScheduler } from '@bryntum/scheduler-react'
import { unplannedGridConfig } from '../../AppConfig'
import Task from '../../lib/Task'
import TaskStore from '../../lib/TaskStore'
import { fetcher } from 'src/fetcher'
import { EventModel } from '@bryntum/scheduler'

interface Props {
	gridRef: MutableRefObject<BryntumGrid>
	schedulerRef: MutableRefObject<BryntumScheduler>
	isAdmin?: boolean
	data: any[]
}

export const UnplannedGrid = (props: Props) => {
	const { gridRef, schedulerRef, isAdmin } = props
	const [store] = useState({
		modelClass: Task,
		// readUrl    : 'data/unplanned.json',
		// autoLoad   : true
	})

	const handleDeleteJob = (id: number) => {
		fetcher
			.delete('/schedule/' + encodeURIComponent(id))
			.then((res) => {
				// setScheduleStatus('delete success')
			})
			.catch((error) => {
				console.log(error)
			})
	}

	useEffect(() => {
		const store = gridRef.current.instance.store as TaskStore
		const eventStore = schedulerRef.current.instance.eventStore
		let detachUpdateListener: Function
		let detachGridUpdateListener: Function

		if (eventStore && store) {
			detachUpdateListener = eventStore.on({
				update: ({ record, changes }: { record: Task; changes: any }) => {
					if ('resourceId' in changes && !record.resourceId) {
						eventStore.remove(record)
						store.add(record)
					}
				},
			})
			detachGridUpdateListener = store.on({
				remove: ({ records }: { records: EventModel[] }) => {
					let record = records[0]
					setTimeout(() => {
						const startDate = record.get('startDate')
						if (!startDate) {
							handleDeleteJob((record as any).Id)
						}
					}, 0)
				},
			})
		}
		return () => {
			detachUpdateListener?.()
			detachGridUpdateListener?.()
		}
	}, [gridRef, schedulerRef])

	useEffect(() => {
		const store = gridRef.current.instance.store as TaskStore
		if (store && props.data) {
			store.data = props.data
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gridRef, props.data])

	return (
		<div className={`unplanned-grid ${isAdmin ? '' : 'pointer-events-none'}`}>
			<BryntumGrid
				ref={gridRef}
				store={store}
				{...unplannedGridConfig}
				features={isAdmin ? undefined : { cellMenu: false }}
			/>
		</div>
	)
}
