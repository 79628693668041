import { EventModel } from '@bryntum/scheduler'

export default class Task extends EventModel {
	Duration: number
	static get $name() {
		return 'Task'
	}

	static get defaults() {
		return {
			// In this demo, default duration for tasks will be hours (instead of days)
			durationUnit: 'm',

			// Use a default name, for better look in the grid if unassigning a new event
			name: 'New event',

			// Use a default icon also
			iconCls: '',
		}
	}

	getDurationMS = (): number => {
		return this.durationMS
	}
}
