import axios, { AxiosError } from 'axios'
import ms from 'ms'
import { CONFIG } from './config'
import Cookies from 'js-cookie'

export const fetcher = axios.create({
	baseURL: CONFIG.PUBLIC_API_BASE_URL,
	timeout: ms('10s'),
})

fetcher.interceptors.request.use(async (config) => {
	// TODO: Handle authorization
	if (!config.headers?.Authorization) {
		if (
			typeof document !== 'undefined' &&
			typeof document?.cookie !== 'undefined'
		) {
			const authToken = Cookies.get('auth_token')
			if (authToken) {
				config.headers.setAuthorization(`Bearer ${authToken}`)
			}
		}
	}

	return config
})

export const handleFetcherError = (error: unknown): Error | AxiosError => {
	if (error instanceof AxiosError && error) {
		if (error?.request?._url) {
			console.error(error.request._url)
		}

		if (error?.response) {
			console.error(error.response.status)
			console.error(error.response.data)
		} else if (error?.message) {
			console.error(error.message)
		} else if (error?.stack) {
			console.error(error.stack)
		}

		throw error
	}

	console.error(error)
	throw error
}
