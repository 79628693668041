export interface ConfigValues {
	PUBLIC_WEB_BASE_URL: string
	PUBLIC_API_BASE_URL: string
}

export const CONFIG_DEFAULTS: ConfigValues = {
	PUBLIC_WEB_BASE_URL: 'http://localhost:3000',
	PUBLIC_API_BASE_URL: 'http://localhost:3001',
}

export const CONFIG: ConfigValues = {
	...CONFIG_DEFAULTS,
	...(process.env.REACT_APP_WEB_BASE_URL
		? {
				PUBLIC_WEB_BASE_URL: process.env.REACT_APP_WEB_BASE_URL,
		  }
		: {}),
	...(process.env.REACT_APP_API_BASE_URL
		? {
				PUBLIC_API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
		  }
		: {}),
}
