/**
 * Application configuration
 */
import { EventModel, StringHelper } from '@bryntum/scheduler'
import {
	BryntumGridProps,
	BryntumSchedulerProps,
} from '@bryntum/scheduler-react'
import { isWeekend } from 'date-fns'
import dayjs, { ManipulateType } from 'dayjs'
// import { addOpacityToHexColor } from '@app/shared'

const getLighterColor = (hexColor, factor) => {
	const color = hexColor.replace('#', '')
	const r = parseInt(color.substring(0, 2), 16)
	const g = parseInt(color.substring(2, 4), 16)
	const b = parseInt(color.substring(4, 6), 16)

	const lighterR = Math.min(Math.round(r + r * factor), 255)
	const lighterG = Math.min(Math.round(g + g * factor), 255)
	const lighterB = Math.min(Math.round(b + b * factor), 255)

	const lighterHexColor = `#${lighterR.toString(16).padStart(2, '0')}${lighterG
		.toString(16)
		.padStart(2, '0')}${lighterB.toString(16).padStart(2, '0')}`

	return lighterHexColor
}

const data = {
	success: true,
	resources: {
		rows: [
			{
				id: 1,
				name: 'Cutting Table',
				textColor: '#fff',
				icon: 'cut',
			},
			{
				id: 2,
				name: "T-100's (Welding)",
				textColor: '#fff',
				icon: 'pain-brush',
			},
			{
				id: 3,
				name: 'Metal Forming',
				textColor: '#fff',
				icon: 'cut',
			},
			{
				id: 4,
				name: 'Hand Weld',
				textColor: '#fff',
				icon: 'search',
			},
			{
				id: 5,
				name: 'Assembly- Single Ply',
				textColor: '#fff',
				icon: 'cog',
			},
			{
				id: 6,
				name: 'Package Single Ply',
				textColor: '#fff',
				icon: 'cog',
			},
			{
				id: 7,
				name: 'Solder and Package Lead',
				textColor: '#fff',
				icon: 'tint',
			},
			{
				id: 8,
				name: 'Soldering',
				textColor: '#fff',
				icon: 'tint',
			},
			{
				id: 9,
				name: 'Jig or Band Saw',
				textColor: '#fff',
				icon: 'tint',
			},
			{
				id: 10,
				name: 'Edge Metal',
				textColor: '#fff',
				icon: 'tint',
			},
			{
				id: 11,
				name: 'Lead Packaing',
				textColor: '#fff',
				icon: 'tint',
			},
			{
				id: 12,
				name: 'Lead Prep',
				textColor: '#fff',
				icon: 'tint',
			},
			{
				id: 13,
				name: 'Weld Lead Pipe',
				textColor: '#fff',
				icon: 'tint',
			},
			{
				id: 14,
				name: 'Prep Single Ply',
				textColor: '#fff',
				icon: 'cog',
			},
		],
	},
	events: {
		rows: [
			{
				id: '1',
				resourceId: 2,
				name: 'ABC101',
				cls: 'forge',
				startDate: '2023-12-01T01:00:00.000',
				endDate: '2023-12-01T08:00:00.000',
				draggable: true,
				resizable: true,
			},
			{
				id: '11',
				resourceId: 2,
				name: 'ABC102',
				startDate: '2023-12-01T15:00:00.000',
				endDate: '2023-12-01T21:00:00.000',
				cls: '',
				draggable: true,
				resizable: true,
			},
			{
				id: '12',
				name: 'ABC103',
				startDate: '2023-12-01T09:00:00.000',
				endDate: '2023-12-01T12:00:00.000',
				cls: '',
				draggable: true,
				resizable: true,
				resourceId: 2,
			},
			{
				id: '2',
				startDate: '2023-12-01T02:00:00.000',
				endDate: '2023-12-01T07:00:00.000',
				cls: '',
				name: 'GFK359',
				draggable: true,
				resizable: true,
				resourceId: 6,
			},
			{
				id: '3',
				startDate: '2023-12-01T08:00:00.000',
				endDate: '2023-12-01T13:00:00.000',
				cls: '',
				name: 'HGH767',
				draggable: true,
				resizable: true,
				resourceId: 6,
			},
			{
				id: '4',
				startDate: '2023-12-01T14:00:00.000',
				endDate: '2023-12-01T19:00:00.000',
				cls: '',
				name: 'LOP22',
				draggable: true,
				resizable: true,
				resourceId: 6,
			},
		],
	},
	dependencies: {
		rows: [
			// {
			//   "id": 1,
			//   "from": 1,
			//   "to": 2,
			//   "fromSide": "left",
			//   "toSide": "left",
			// },
		],
	},
	themes: [
		{
			value: 'classic',
			label: 'Classic',
		},
		{
			value: 'classic-light',
			label: 'Classic Light',
		},
		{
			value: 'classic-dark',
			label: 'Classic Dark',
		},
		{
			value: 'material',
			label: 'Material',
		},
		{
			value: 'stockholm',
			label: 'Stockholm',
		},
	],
}

const schedulerConfig: BryntumSchedulerProps = {
	eventStyle: null,
	eventColor: null,
	rowHeight: 50,
	barMargin: 8,
	zoomOnMouseWheel: false,
	zoomOnTimeAxisDoubleClick: false,
	createEventOnDblClick: false,
	scheduleMenuFeature: false,
	startDate: new Date(dayjs().add(-7, 'day').toDate().setHours(7, 0, 0, 0)), // minus 1 day from calendar date
	endDate: new Date(dayjs().add(7, 'day').toDate().setHours(17, 0, 0, 0)), // plus 1 day from calendar date
	timeAxis: {
		continuous: false,
		generateTicks(
			start: Date,
			end: Date,
			unit: ManipulateType,
			increment: number
		) {
			const ticks = []
			while (start.getTime() < end.getTime()) {
				if (
					start.getHours() >= 7 &&
					start.getHours() < 17 &&
					!isWeekend(start)
				) {
					ticks.push({
						id: ticks.length + 1,
						startDate: start,
						endDate: new Date(dayjs(start).add(increment, unit).toDate()),
					})
				}
				start = new Date(dayjs(start).add(increment, unit).toDate())
			}

			return ticks
		},
	},
	columns: [
		{
			text: 'Resource',
			width: 200,
			field: 'name',
		},
	],
	stripeFeature: false,
	dependenciesFeature: true,
	dependencyEditFeature: {
		showLagField: false,
	},
	timeRangesFeature: true,
	eventDragFeature: {
		constrainDragToResource: true,
	},
	viewPreset: {
		base: 'hourAndDay',
		tickWidth: 40,
		displayDateFormat: 'HH:mm',
		columnLinesFor: 0,
		mainUnit: 'minute',
		timeResolution: {
			unit: 'minute',
			increment: 15,
		},
		headers: [
			{
				unit: 'd',
				align: 'center',
				dateFormat: 'ddd DD MMM',
			},
			{
				unit: 'h',
				align: 'center',
				dateFormat: 'HH:mm',
			},
			{
				unit: 'minutes',
				align: 'center',
				dateFormat: 'mm',
				increment: 15,
			},
		],
	},
	eventRenderer: ({ eventRecord, resourceRecord, renderData }) => {
		const bgColor = eventRecord.bg || ''
		renderData.style = `background:${bgColor};border-color:${bgColor};color:#fff`
		if (eventRecord.Status === 'C') {
			renderData.style = `
			  background: repeating-linear-gradient(135deg, ${bgColor}, ${bgColor} 10px, ${getLighterColor(
				bgColor,
				0.15
			)} 10px, ${getLighterColor(bgColor, 0.15)} calc(2 * 10px));
			`
			renderData.iconCls.add('b-fa b-fa-light', `b-fa-circle-check`)
		} else if (eventRecord.Status === 'S') {
			renderData.iconCls.add('b-fa', `b-fa-play`)
		}

		return StringHelper.encodeHtml(eventRecord.name)
	},
	eventStore: {
		fields: ['hide'],
	},
	features: {
		dependencies: {
			radius: 5,
		},
		dependencyEdit: {
			editorConfig: {
				items: {
					// Custom label for the type field
					typeField: {
						label: 'Kind',
					},
				},

				bbar: {
					items: {
						// Hiding save button
						saveButton: {
							hidden: true,
						},
					},
				},
			},
		},
		eventMenu: {
			items: {
				copyEvent: false,
				cutEvent: false,
				deleteEvent: false,
				unassign: {
					text: 'Unassign',
					icon: 'b-fa b-fa-user-times',
					weight: 200,
					onItem: ({
						eventRecord,
						resourceRecord,
					}: {
						eventRecord: EventModel
						resourceRecord: any
					}) => {
						eventRecord.setStartEndDate(null, null)
						const durationUnit = eventRecord.get('durationUnit')
						const duration =
							durationUnit === 'm' || durationUnit === 'minute'
								? eventRecord.get('duration')
								: eventRecord.get('duration') * 60 * 24
						setTimeout(() => {
							eventRecord.set('durationUnit', 'm')
							eventRecord.set('duration', duration)
						}, 100)

						return eventRecord.unassign(resourceRecord)
					},
				},
			},
			processItems: ({ eventRecord, items }) => {
				if (eventRecord.Status === 'C') {
					items.hide = {
						text: 'Hide',
						icon: 'b-fa b-fa-fw b-fa-eye-slash',
						cls: 'b-separator',
						weight: 540,
						onItem: ({ eventRecord }) => {
							eventRecord.hide = true
						},
					}
				}
			},
		},
		eventEdit: {
			items: {
				nameField: {
					readOnly: true,
				},
			},
			editorConfig: {
				bbar: {
					items: {
						deleteButton: null,
					},
				},
			},
		},
		eventDragCreate: false,
		eventTooltip: {
			template: (data) => {
				const eventRecordData = data.eventRecord.data
				const {
					name,
					ProdCode,
					Resource,
					// Duration,
					OriginNum,
					DueDate,
					ShipType,
					SegDuration,
				} = eventRecordData

				return `<div>
            <p>WO: <b>${name}</b></p>
            <p>Item: ${ProdCode}</p>
            <p>Res: ${Resource}</p>
            <p>Mins: ${SegDuration}</p>
            ${OriginNum ? `<p>SO: ${OriginNum}</p>` : ''} 
            ${
							DueDate
								? `<p>Due Dt. ${dayjs(DueDate).format('MM/DD/YYYY')}</p>`
								: ''
						} 
            ${ShipType ? `<p>Ship Type: ${ShipType}</p>` : ''} 
            </div>`
			},
		},
	},
}

const unplannedGridConfig: BryntumGridProps = {
	rowHeight: 160,
	cls: 'b-unplannedgrid',
	columns: [
		{
			text: 'Unassigned jobs',
			flex: 1,
			field: 'name',
			htmlEncode: false,
			renderer: ({ record }: { record: any }) =>
				StringHelper.xss`<div>
        <p>WO: <b>${record.name}</b></p>
        <p>Item: ${record.ProdCode}</p>
        <p>Res: ${record.Resource}</p>
        <p>Mins: ${record.SegDuration}</p>
        <p>${record.OriginNum ? `SO: ${record.OriginNum}` : ''}</p>
        <p>${
					record.DueDate
						? `Due Dt. ${dayjs(record.DueDate).format('MM/DD/YYYY')}`
						: ''
				}</p>
				<p>${record.ShipType ? `Ship Type: ${record.ShipType}` : ''}</p>
        </div>`,
		},
		{
			text: 'Duration',
			width: 40,
			align: 'right',
			editor: false,
			field: 'duration',
			renderer: ({ record }: { record: any }) =>
				StringHelper.xss`${record.duration ?? record.Duration} ${
					record.durationUnit
				}`,
		},
	],
	stripeFeature: true,
	sortFeature: 'name',
}

export { schedulerConfig, unplannedGridConfig, data }
