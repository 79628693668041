// import React, { useEffect, useState } from "react";
import './Popup.scss'

const Popup2 = (props) => {
	// const {
	//   eventRecord,
	//   resourceStore,
	//   eventStore,
	//   dependencyStore,
	//   closePopup,
	// } = props;
	// const [state, setState] = useState({
	//   name: "",
	//   startDate: null,
	//   endDate: null,
	//   eventType: "",
	//   location: "",
	//   resourceId: null,
	// });

	// useEffect(() => {
	//   let newState = { ...state };
	//   for (const key in state) {
	//     if (eventRecord[key]) {
	//       newState[key] = eventRecord[key];
	//     }
	//   }
	//   setState(newState);
	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [eventRecord]);

	// const dataChangedHandler = ({ target }) => {
	//   setState((prevState) => {
	//     return {
	//       ...prevState,
	//       [target.name]: target.value,
	//     };
	//   });
	// };

	// const startDateChangeHandler = (startDate) => {
	//   setState((prevState) => {
	//     return {
	//       ...prevState,
	//       startDate: startDate,
	//     };
	//   });
	// };

	// const endDateChangeHandler = (endDate) => {
	//   setState((prevState) => {
	//     return {
	//       ...prevState,
	//       endDate: endDate,
	//     };
	//   });
	// };

	// const saveClickHandler = () => {
	//   const newEventRecord = eventRecord;

	//   // We need to reset this flag to tell scheduler that this is a real event
	//   newEventRecord.isCreating = false;

	//   // Update the eventRecord using the default setters
	//   newEventRecord.beginBatch();
	//   for (const key in state) {
	//     newEventRecord[key] = state[key];
	//   }

	//   // Add the eventRecord to the eventStore if it is not already there
	//   if (!newEventRecord.eventStore) {
	//     eventStore.add(newEventRecord);
	//   }
	//   newEventRecord.endBatch();

	//   closePopup();
	// };

	// const onDependencyChange = (data) => {
	//   // console.log(data?.target?.value);
	//   console.log("data", dependencyStore.data);
	// };

	// const resourceItems = resourceStore.map((resource) => (
	//   <MenuItem key={resource.id} value={resource.id}>
	//     {resource.name}
	//   </MenuItem>
	// ));

	// const eventItems = eventStore.map((event) => (
	//   <MenuItem key={event.id} value={event.id}>
	//     {event.name}
	//   </MenuItem>
	// ));
	return <></>
	// return (
	//   <div className="popup-mask">
	//     <div className="popup">
	//       <header>{state.name}&nbsp;</header>
	//       <article>
	//         <TextField
	//           name="name"
	//           label="Event name"
	//           onChange={dataChangedHandler}
	//           value={state.name}
	//           fullWidth
	//           style={{ marginBottom: 10 }}
	//         />

	//         <FormControl style={{ marginBottom: 10, width: "100%" }}>
	//           {/* <InputLabel>Action</InputLabel> */}
	//           <Select
	//             label="Action"
	//             name="resourceId"
	//             onChange={dataChangedHandler}
	//             value={state.resourceId}
	//           >
	//             {resourceItems}
	//           </Select>
	//         </FormControl>

	//         <FormControl style={{ marginBottom: 10, width: "50%" }}>
	//           <InputLabel>From</InputLabel>
	//           <Select
	//             label="Action"
	//             name="from-dependency"
	//             onChange={onDependencyChange}
	//             // value={state.resourceId}
	//           >
	//             {eventItems}
	//           </Select>
	//         </FormControl>

	//         <FormControl style={{ marginBottom: 10, width: "50%" }}>
	//           <InputLabel>To</InputLabel>
	//           <Select
	//             label="Action"
	//             name="to-dependency"
	//             onChange={onDependencyChange}
	//             // value={state.resourceId}
	//           >
	//             {eventItems}
	//           </Select>
	//         </FormControl>

	//         {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
	//                           <KeyboardDateTimePicker
	//                               name="startDate"
	//                               label="Start"
	//                               ampm={false}
	//                               format="yyyy-MM-dd HH:mm"
	//                               style={{ width : '49%', marginRight : 5 }}
	//                               value={state.startDate}
	//                               onChange={startDateChangeHandler}
	//                           ></KeyboardDateTimePicker>
	//                           <KeyboardDateTimePicker
	//                               name="endDate"
	//                               label="End"
	//                               ampm={false}
	//                               format="yyyy-MM-dd HH:mm"
	//                               style={{ width : '49%', marginLeft : 5 }}
	//                               value={state.endDate}
	//                               onChange={endDateChangeHandler}
	//                           ></KeyboardDateTimePicker>
	//                       </MuiPickersUtilsProvider> */}

	//         <input
	//           type="datetime-local"
	//           value={new Date() as any}
	//           onChange={startDateChangeHandler}
	//         />

	//         <input
	//           type="datetime-local"
	//           value={state.endDate}
	//           onChange={endDateChangeHandler}
	//         />
	//       </article>

	//       <footer>
	//         <Button
	//           variant="contained"
	//           color="secondary"
	//           onClick={props.closePopup}
	//         >
	//           Cancel
	//         </Button>

	//         <Button
	//           variant="contained"
	//           color="primary"
	//           onClick={saveClickHandler}
	//         >
	//           Save
	//         </Button>
	//       </footer>
	//     </div>
	//   </div>
	// );
}

export default Popup2
